import { useState, useEffect, createContext, useContext } from "react";
import { useToast } from "@chakra-ui/react";
import firebase from "~/firebase/clientApp";
import * as Sentry from "@sentry/nextjs";

const COLLECTION_SHOPS = "shops";

export const ShopContext = createContext();

export default function ShopContextComponent({
  initialShop,
  domain,
  children,
}) {
  const [shopDomain, setShopDomain] = useState(domain);
  const [shop, setShop] = useState(initialShop || null);
  const [loadingShop, setLoadingShop] = useState(initialShop ? false : true); // Helpful, to update the UI accordingly.
  const toast = useToast();

  const showErrorToast = () => {
    toast({
      position: "top",
      title: "Ocurrió un error.",
      description: "No pudimos encontrar los datos de tu tienda.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(COLLECTION_SHOPS)
      .where("domains", "array-contains", shopDomain)
      .limit(1)
      .onSnapshot(
        (querySnapshot) => {
          try {
            if (!querySnapshot.empty) {
              let doc = querySnapshot.docs[0];
              let shop = { id: doc.id, ...doc.data() };
              setShop(shop);
              Sentry.setContext("shop", shop);
              Sentry.setTag("shopId", shop.id);
            }
          } catch (error) {
            Sentry.setContext("shop", null);
            Sentry.setTag("shopId", null);
            Sentry.captureException(error);
            showErrorToast();
          } finally {
            setLoadingShop(false);
          }
        },
        (error) => {
          Sentry.setContext("shop", null);
          Sentry.setTag("shopId", null);
          Sentry.captureException(error);
          showErrorToast();
          setLoadingShop(false);
        }
      );

    // Unsubscribe listener on unmount
    return () => unsubscriber();
  }, [shopDomain]);

  return (
    <ShopContext.Provider
      value={{ shop, loadingShop, shopDomain, setShopDomain }}
    >
      {children}
    </ShopContext.Provider>
  );
}

// Custom hook that shorhands the context!
export const useShop = () => useContext(ShopContext);
