import firebase from "~/firebase/clientApp";
import { isMobile } from "~/components/MediaQuery";
import { detect } from "detect-browser";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/nextjs";
import { MAX_VISITOR_ACTIONS_RECORDS } from "~/utils/Constants";

const FIRESTORE_COLLECTION_USERS = "users";
export const FIREBASE_STORAGE_USERS = "users";
export const FIREBASE_STORAGE_USERS_IMAGES = "images";
export const MESSAGE_SENDER_TYPE_VISITOR = "visitor";
export const MESSAGE_SENDER_TYPE_SHOP_MEMBER = "shop_member";
const GEO_IP_LOOKUP_API_URL = "https://json.geoiplookup.io/";

const memberConnection = (user) => {
  let connectionAgent = {
    type: "browser_tab",
    language: navigator.language || navigator.userLanguage,
  };

  const browser = detect();

  if (browser)
    connectionAgent = {
      ...connectionAgent,
      ...{
        browser: browser,
      },
    };

  return {
    user: {
      displayName: user.displayName,
      avatarUrl: user.photoURL,
      email: user.email,
    },
    agent: connectionAgent,
    connectedAt: firebase.database.ServerValue.TIMESTAMP,
  };
};

// Reports the current user presence as a member for the shop.
// Only shop members will be authorized in the backend for this operation.
export async function startTrackingUserPresenceAsMember(shopId) {
  const user = firebase.auth().currentUser;
  // Check that we are not running server side
  if (user && typeof window !== "undefined") {
    const memberActivityOnlineDatabaseRef = firebase
      .database()
      .ref(`shops/${shopId}/activity/members/online/${user.uid}/connections`);

    // Create a new connection for this instance (may be a tab from the browser, a mobile device or even multiple devices)
    const currentConnectionRef = memberActivityOnlineDatabaseRef.push();
    const connectionId = currentConnectionRef.getKey();

    // The promise returned from .onDisconnect().set() / .update() / .remove() will
    // resolve as soon as the server acknowledges the onDisconnect()
    // request, NOT once we've actually disconnected:
    // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

    // If we are currently connected, then use the 'onDisconnect()'
    // method to add a set which will only trigger once this
    // client has disconnected by closing the app,
    // losing internet, or any other means.
    firebase
      .database()
      .ref(".info/connected")
      .on(
        "value",
        (snapshot) => {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() == false) {
            return;
          }

          // Set activity on the online node
          currentConnectionRef
            .onDisconnect()
            .remove()
            .then(() => currentConnectionRef.set(memberConnection(user)));
        },
        (error) => Sentry.captureException(error)
      );

    return Promise.resolve(connectionId);
  }
  // Server-side resolve but do nothing
  else return Promise.resolve();
}

export async function forceReportUserPresenceAsMemberAsOffline(
  shopId,
  connectionId
) {
  const user = firebase.auth().currentUser;

  // Check that we are not running server side
  if (user && typeof window !== "undefined") {
    // Validate that there is a connectionId and a shopId, to avoid messeing up the DB
    if (!connectionId || typeof connectionId !== "string")
      return Promise.reject(
        new Error(
          "A Connection ID (string) is required to track members presence"
        )
      );
    if (!shopId || typeof shopId !== "string")
      return Promise.reject(
        new Error("A Shop ID (string) is required to track members presence")
      );

    const memberActivityOnlineDatabaseRef = firebase
      .database()
      .ref(
        `shops/${shopId}/activity/members/online/${user.uid}/connections/${connectionId}`
      );

    return Promise.all([
      memberActivityOnlineDatabaseRef.remove(), // Remove online connection node
      memberActivityOnlineDatabaseRef.onDisconnect().cancel(), // Remove previously setted server-side listeners.
    ]).catch((error) => Sentry.captureException(error));
  } else return Promise.resolve();
}

// Refreshes the user's presence data as a member.
// Useful when there are changes in the current logged user that need to be sent to the backend (eg: avatar's url changed)
export async function forceRefreshUserPresenceAsMember(shopId, connectionId) {
  const user = firebase.auth().currentUser;

  // Check that we are not running server side
  if (user && typeof window !== "undefined") {
    // Validate that there is a connectionId and a shopId, to avoid messeing up the DB
    if (!connectionId || typeof connectionId !== "string")
      return Promise.reject(
        new Error(
          "A Connection ID (string) is required to track members presence"
        )
      );
    if (!shopId || typeof shopId !== "string")
      return Promise.reject(
        new Error("A Shop ID (string) is required to track members presence")
      );

    const memberActivityOnlineDatabaseRef = firebase
      .database()
      .ref(
        `shops/${shopId}/activity/members/online/${user.uid}/connections/${connectionId}`
      );

    return memberActivityOnlineDatabaseRef.set(memberConnection(user));
  } else return Promise.resolve();
}

// Tracks an action of the current user as a shop's member for the given Shop ID and Connection ID
export async function trackUserActionAsMember(shopId, connectionId, action) {
  const user = firebase.auth().currentUser;

  // Check that we are not running server side
  if (user && typeof window !== "undefined") {
    // Validate that there is a connectionId and a shopId, to avoid messeing up the DB
    if (!connectionId || typeof connectionId !== "string")
      return Promise.reject(
        new Error(
          "A Connection ID (string) is required to track members presence"
        )
      );
    if (!shopId || typeof shopId !== "string")
      return Promise.reject(
        new Error("A Shop ID (string) is required to track members presence")
      );

    const memberActivityActionDatabaseRef = firebase
      .database()
      .ref(
        `shops/${shopId}/activity/members/online/${user.uid}/connections/${connectionId}/action`
      );

    return memberActivityActionDatabaseRef.set({
      ...action,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  } else return Promise.resolve();
}

// Reports the current user presence as a visitor of the shop.
export async function startTrackingUserPresenceAsVisitor(shopId) {
  const user = firebase.auth().currentUser;

  const visitorConnection = (ipData) => {
    let connectionData = {
      type: "browser_tab",
      deviceType: isMobile() ? "mobile" : "desktop",
      language: navigator.language || navigator.userLanguage,
      connectedAt: firebase.database.ServerValue.TIMESTAMP,
    };

    if (ipData)
      connectionData = {
        ...connectionData,
        ...{
          ip: ipData.ip || null,
          ipLatitude: ipData.latitude || null,
          ipLongitude: ipData.longitude || null,
          ipCountryCode: ipData.country_code || null,
          ipRegion: ipData.region || null,
        },
      };

    const browser = detect();

    if (browser)
      connectionData = {
        ...connectionData,
        ...{
          browser: browser,
        },
      };

    return connectionData;
  };

  // Check that we are not running server side
  if (user && typeof window !== "undefined") {
    // Do a GEO IP Lookup to get some insights on the user's location
    let ipData;
    try {
      const geoIpResponse = await fetch(GEO_IP_LOOKUP_API_URL);

      if (!geoIpResponse || geoIpResponse.status !== 200) {
        Sentry.captureException(geoIpResponse);
      } else {
        ipData = await geoIpResponse.json();
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    const visitorActivityOnlineDatabaseRef = firebase
      .database()
      .ref(`shops/${shopId}/activity/visitors/online/${user.uid}/connections`);

    // Create a new connection for this instance (may be a tab from the browser, a mobile device or even multiple devices)
    const currentConnectionRef = visitorActivityOnlineDatabaseRef.push();
    const connectionId = currentConnectionRef.getKey();

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase
      .database()
      .ref(".info/connected")
      .on(
        "value",
        (snapshot) => {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() == false) {
            return;
          }

          // Set activity on the online node
          currentConnectionRef
            .onDisconnect()
            .remove()
            .then(() => currentConnectionRef.set(visitorConnection(ipData)));
        },
        (error) => Sentry.captureException(error)
      );

    return Promise.resolve(connectionId);
  }
  // Server-side resolve but do nothing
  else return Promise.resolve();
}

// Tracks an action performed by a visitor, such as 'view_product'
export async function trackUserActionAsVisitor(
  shopId,
  connectionId,
  actionData
) {
  const user = firebase.auth().currentUser;

  // Check that we are not running server side
  if (user && typeof window !== "undefined") {
    // Validate that there is a connectionId and a shopId, to avoid messeing up the DB
    if (!connectionId || typeof connectionId !== "string")
      return Promise.reject(
        new Error(
          "A Connection ID (string) is required to track visitor actions"
        )
      );
    if (!shopId || typeof shopId !== "string")
      return Promise.reject(
        new Error("A Shop ID (string) is required to track visitor actions")
      );

    const visitorActivityOnlineActionDatabaseRef = firebase
      .database()
      .ref(
        `shops/${shopId}/activity/visitors/online/${user.uid}/connections/${connectionId}/action`
      );

    const visitorActivityRecordActionDatabaseRef = firebase
      .database()
      .ref(`shops/${shopId}/activity/visitors/record/${user.uid}/actions/`);

    const action = {
      ...actionData,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
    };

    const currentActivityRecords = await visitorActivityRecordActionDatabaseRef
      .once("value")
      .then((snapshot) => Promise.resolve(snapshot.val() || []));

    // Only the latest 'MAX_VISITOR_ACTIONS_RECORDS' actions are stored. This is to prevent each user from generating potentially huge records.
    const newActivityRecords = [action]
      .concat(currentActivityRecords)
      .slice(0, MAX_VISITOR_ACTIONS_RECORDS);

    return Promise.all([
      visitorActivityOnlineActionDatabaseRef.set(action),
      visitorActivityRecordActionDatabaseRef.set(newActivityRecords),
    ]);
  }
  // Server-side resolve but do nothing
  else return Promise.resolve();
}

// Sends a chat message as a shop visitor
export async function sendVisitorChatMessage(
  fromUserId,
  shopId,
  messageType,
  payload
) {
  const message = {
    senderType: MESSAGE_SENDER_TYPE_VISITOR,
    messageType: messageType,
    payload: payload,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  };

  return Promise.all([
    // Add new message to messages list
    firebase
      .database()
      .ref(`shops/${shopId}/chats/${fromUserId}/messages/`)
      .push(message),
    // Update last message
    firebase
      .database()
      .ref(`shops/${shopId}/chats/${fromUserId}/lastMessage`)
      .update(message),
    // Increment unread count
    firebase
      .database()
      .ref(`shops/${shopId}/chats/${fromUserId}/`)
      .update({ unreadCount: firebase.database.ServerValue.increment(1) }),
    // Clear typing presence
    clearVisitorAsTypingInChat(fromUserId, shopId),
  ]);
}

// Sends a chat message as a shop member
// Note: The backend will validate that the sender user belongs to the shop before confirming the message.
export async function sendShopMemberChatMessage(
  recipientUserId,
  shopId,
  senderId,
  senderDisplayName,
  senderAvatarUrl,
  messageType,
  payload
) {
  const message = {
    senderType: MESSAGE_SENDER_TYPE_SHOP_MEMBER,
    senderId,
    senderDisplayName,
    senderAvatarUrl,
    messageType: messageType,
    payload: payload,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  };

  return Promise.all([
    // Add new message to messages list
    firebase
      .database()
      .ref(`shops/${shopId}/chats/${recipientUserId}/messages/`)
      .push(message),
    // Update last message
    firebase
      .database()
      .ref(`shops/${shopId}/chats/${recipientUserId}/lastMessage`)
      .update(message),
    // Clear typing presence
    clearMemberAsTypingInChat(recipientUserId, shopId),
  ]);
}

// Sets the user as typing in a chat as a visitor
export async function setVisitorAsTypingInChat(fromUserId, shopId) {
  const typingPayload = {
    senderType: MESSAGE_SENDER_TYPE_VISITOR,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  };

  return firebase
    .database()
    .ref(`shops/${shopId}/chats/${fromUserId}/typing/${fromUserId}`)
    .update(typingPayload);
}

// Clears the user from typing in a chat as a visitor
export async function clearVisitorAsTypingInChat(fromUserId, shopId) {
  return firebase
    .database()
    .ref(`shops/${shopId}/chats/${fromUserId}/typing/${fromUserId}`)
    .remove();
}

// Sets the current user as typing in a chat as a member
export async function setMemberAsTypingInChat(recipientUserId, shopId) {
  const user = firebase.auth().currentUser;

  if (user) {
    const typingPayload = {
      senderType: MESSAGE_SENDER_TYPE_SHOP_MEMBER,
      senderId: user.uid,
      senderDisplayName: user.displayName,
      senderAvatarUrl: user.photoURL,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
    };

    return firebase
      .database()
      .ref(`shops/${shopId}/chats/${recipientUserId}/typing/${user.uid}`)
      .update(typingPayload);
  }
}

// Clears the current user of typing in a chat as a member
export async function clearMemberAsTypingInChat(recipientUserId, shopId) {
  const user = firebase.auth().currentUser;

  if (user)
    return firebase
      .database()
      .ref(`shops/${shopId}/chats/${recipientUserId}/typing/${user.uid}`)
      .remove();
}

// Marks a given chat as read
export async function markChatAsRead(recipientUserId, shopId) {
  firebase
    .database()
    .ref(`shops/${shopId}/chats/${recipientUserId}/`)
    .update({ unreadCount: 0 });
}

// Adds a product to the user's cart.
export async function addProductToCart(
  shopId,
  productId,
  productCartData,
  userId
) {
  return firebase
    .database()
    .ref(`shops/${shopId}/carts/${userId}/products/${productId}`)
    .set(productCartData);
}

// Removes a product from the user's cart.
export async function removeProductFromCart(shopId, productId, userId) {
  return firebase
    .database()
    .ref(`shops/${shopId}/carts/${userId}/products/${productId}`)
    .remove();
}

// Updates a product from the user's cart.
export async function updateProductFromCart(
  shopId,
  productId,
  productCartData,
  userId
) {
  return firebase
    .database()
    .ref(`shops/${shopId}/carts/${userId}/products/${productId}`)
    .update(productCartData);
}

export async function setUserDisplayname(displayName) {
  var user = firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection(FIRESTORE_COLLECTION_USERS)
    .doc(user.uid)
    .update({
      displayName: displayName,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      return user
        .updateProfile({
          displayName: displayName,
        })
        .then(() => Promise.resolve(displayName));
    });
}

export async function setUserAvatarWithUrl(avatarUrl) {
  var user = firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection(FIRESTORE_COLLECTION_USERS)
    .doc(user.uid)
    .update({
      avatarUrl: avatarUrl,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      return user
        .updateProfile({
          photoURL: avatarUrl,
        })
        .then(() => Promise.resolve(avatarUrl));
    });
}

export async function setUserAvatarWithImageFile(localAvatarImageFile) {
  const userId = firebase.auth().currentUser.uid;

  const fileRef = firebase
    .storage()
    .ref()
    .child(FIREBASE_STORAGE_USERS)
    .child(userId)
    .child(FIREBASE_STORAGE_USERS_IMAGES)
    .child(uuidv4());

  const metadata = {
    customMetadata: {
      uploadedBy: userId,
    },
  };

  return fileRef
    .put(localAvatarImageFile, metadata)
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL();
    })
    .then((downloadURL) => {
      return setUserAvatarWithUrl(downloadURL);
    });
}
