import { useState, useEffect, createContext, useContext } from "react";
import firebase from "~/firebase/clientApp";
import * as Sentry from "@sentry/nextjs";
import {
  startTrackingUserPresenceAsMember,
  forceReportUserPresenceAsMemberAsOffline,
  trackUserActionAsMember,
} from "~/actions/UserActions";
import { userIdToColor } from "~/utils/Formatters";

export const MembersContext = createContext();

export default function MembersContextComponent({ shopId, children }) {
  const [membersPresence, setMembersPresence] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentActions, setCurrentActions] = useState(new Map());
  const [connectionId, setConnectionId] = useState();

  useEffect(() => {
    // Start tracking the user presence and activity
    startTrackingUserPresenceAsMember(shopId).then((connectionId) =>
      setConnectionId(connectionId)
    );

    // On dismount, force report the user as offline.
    // This is useful when switching shops without going actually offline.
    // Whith this, the user gets reporter as offline for the shop that they stoped looking at.
    return () => {
      if (connectionId)
        forceReportUserPresenceAsMemberAsOffline(shopId, connectionId);
    };
  }, [shopId]);

  // Recompute online members after each members presence update
  useEffect(() => {
    setOnlineUsers(
      membersPresence
        .map((currentPresence) => {
          let user, lastConnection;

          // For each user's presence data, use the user's data contained in the latest connection
          Object.values(currentPresence.connections).forEach(
            (connectionData) => {
              if (
                !lastConnection ||
                connectionData.connectedAt > lastConnection
              ) {
                user = connectionData.user;
                lastConnection = connectionData.connectedAt;
              }
            }
          );

          return {
            ...user,
            id: currentPresence.userId,
            accentColor: userIdToColor(currentPresence.userId),
          };
        })
        // Order users alphabetically to prevent UI shifts
        .sort((userA, userB) =>
          userA.displayName.localeCompare(userB.displayName)
        )
    );
  }, [membersPresence]);

  // Recompute current actions map aftear each members presence update
  useEffect(() => {
    let newCurrentActionsMap = new Map();

    membersPresence.forEach((currentPresence) => {
      let actionType, actionPayload, actionUpdatedAt, user;

      // For each user's presence data, determine the user's most updated action
      Object.values(currentPresence.connections).forEach((connectionData) => {
        // Check if the current connection has an associated action
        if (connectionData.action) {
          if (
            !actionUpdatedAt ||
            connectionData.action.updatedAt > actionUpdatedAt
          ) {
            actionType = connectionData.action.type;
            user = {
              id: currentPresence.userId,
              accentColor: userIdToColor(currentPresence.userId),
              ...connectionData.user,
            };
            actionPayload = connectionData.action.payload;
            actionUpdatedAt = connectionData.action.updatedAt;
          }
        }
      });

      // If an action was found for the current user, then push it to the actions Map
      if (actionType) {
        let currentPresencesForAction =
          newCurrentActionsMap.get(actionType) || [];

        newCurrentActionsMap.set(actionType, [
          ...currentPresencesForAction,
          { user: user, payload: actionPayload },
        ]);
      }
    });

    setCurrentActions(newCurrentActionsMap);
  }, [membersPresence]);

  const addMemberPresence = (userId, presenceData) => {
    const newMemberPresence = { userId: userId, ...presenceData };
    setMembersPresence((actualMembersPresence) =>
      actualMembersPresence.concat([newMemberPresence])
    );
  };

  const updateMemberPresence = (userId, presenceData) => {
    const updatedMemberPresence = { userId: userId, ...presenceData };
    setMembersPresence((actualMembers) => {
      return actualMembers.map((memberPresence) => {
        if (memberPresence.userId === updatedMemberPresence.userId)
          return updatedMemberPresence;
        else return memberPresence;
      });
    });
  };

  const removeMemberPresence = (userId) => {
    setMembersPresence((actualMembers) =>
      actualMembers.filter((memberPresence) => memberPresence.userId !== userId)
    );
  };

  useEffect(() => {
    const membersPresenceDatabaseRef = firebase
      .database()
      .ref(`shops/${shopId}/activity/members/online`);

    membersPresenceDatabaseRef.on(
      "child_added",
      (data) => addMemberPresence(data.key, data.val()),
      (error) => Sentry.captureException(error)
    );

    membersPresenceDatabaseRef.on(
      "child_changed",
      (data) => updateMemberPresence(data.key, data.val()),
      (error) => Sentry.captureException(error)
    );

    membersPresenceDatabaseRef.on(
      "child_removed",
      (data) => removeMemberPresence(data.key),
      (error) => Sentry.captureException(error)
    );

    // Unsubscribe listener on unmount
    return () => {
      setMembersPresence([]);
      membersPresenceDatabaseRef.off();
    };
  }, [shopId]);

  // Tracks an action from the current user for the curent connection
  const trackMemberAction = (actionType, actionPayload) => {
    if (connectionId && actionType)
      trackUserActionAsMember(shopId, connectionId, {
        type: actionType,
        payload: actionPayload || null,
      });
  };

  return (
    <MembersContext.Provider
      value={{ onlineUsers, currentActions, connectionId, trackMemberAction }}
    >
      {children}
    </MembersContext.Provider>
  );
}

// Custom hook that shorthands the context!
export const useMembers = () => useContext(MembersContext);
